<template>
  <DashboardSection
    :title="locale.title"
    class="profile-platforms"
  >
    <div class="profile-platforms__buttons">
      <AuthButton
        platform="twitch"
        class="profile-platforms__button"
        request-url="streamer/attach/twitch"
        :attaching="attachingTwitch"
        :plain="!!twitch"
        :attached="!!twitch"
      >
        {{ twitch ? twitch.nickname : `${$t('button.attach.static')} Twitch` }}
      </AuthButton>
      <AuthButton
        platform="youtube"
        class="profile-platforms__button"
        request-url="streamer/attach/youtube"
        :attaching="attachingYoutube"
        :plain="!!youtube"
        :attached="!!youtube"
      >
        {{ youtube ? youtube.nickname : `${$t('button.attach.static')} YouTube` }}
      </AuthButton>
      <AuthButton
        platform="trovo"
        class="profile-platforms__button"
        request-url="streamer/attach/trovo"
        :attaching="attachingTrovo"
        :plain="!!trovo"
        :attached="!!trovo"
      >
        {{ trovo ? trovo.nickname : `${$t('button.attach.static')} Trovo` }}
      </AuthButton>
    </div>

    <div class="profile-platforms__error">
      <AuthErrorTwitch v-if="error && platform === 'twitch'" />
      <AuthErrorYoutube v-if="error && platform === 'youtube'" />
      <AuthErrorTrovo v-if="error && platform === 'trovo'" />
      <AuthError v-if="reason">
        {{ reason === AuthErrorReasons.AccountAlreadyExists ? locale.accountAlreadyExists : '' }}
      </AuthError>
    </div>

    <div class="profile-platforms__help">
      <TextLink
        :href="$t('links.discord')"
        target="_blank"
      >
        {{ locale.writeUsToDetach }}
      </TextLink>
    </div>
  </DashboardSection>
</template>

<script setup>
// todo: Работа с ошибками авторизации ужасна, нужно подумать над оптимизацией
import { AuthErrorReasons } from 'Auth/components/auth-error-reasons'
import AuthError from 'Auth/components/AuthError'
import AuthErrorTrovo from 'Auth/components/AuthErrorTrovo'
import AuthErrorTwitch from 'Auth/components/AuthErrorTwitch'
import AuthErrorYoutube from 'Auth/components/AuthErrorYoutube'
import AuthButton from 'components/buttons/AuthButton.vue'
import TextLink from 'components/buttons/TextLink'
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import { Api } from 'lib/api/instance'
import { useLocale, useRootLocale } from 'lib/helpers/useLocale'
import { useRoute } from 'lib/helpers/useRoute'
import { useRouter } from 'lib/helpers/useRouter'
import { computed, onMounted } from 'vue'

import { vm } from '@/main'

const route = useRoute()
const router = useRouter()

const locale = computed(() => ({
  title: useLocale('platforms.title'),
  writeUsToDetach: useLocale('platforms.writeUsToDetach'),
  twitchAuthError: useRootLocale('views.auth.streamerSignin.twitchAuthError'),
  youtubeAuthError: useRootLocale('views.auth.streamerSignin.youtubeAuthError'),
  trovoAuthError: useRootLocale('views.auth.streamerSignin.trovoAuthError'),
  contactSupport: useRootLocale('views.auth.streamerSignin.contactSupport'),
  accountAlreadyExists: useRootLocale('views.auth.streamerSignin.accountAlreadyExists'),
}))

const youtube = computed(() => vm.$store.state.auth.youtube)
const twitch = computed(() => vm.$store.state.auth.twitch)
const trovo = computed(() => vm.$store.state.auth.trovo)
const error = computed(() => route.query.error)
const attachId = computed(() => route.query.attach)
const platform = computed(() => route.query.platform)
const reason = computed(() => route.query.reason)
const attachingTwitch = computed(() => attachId.value && platform.value === 'twitch')
const attachingYoutube = computed(() => attachId.value && platform.value === 'youtube')
const attachingTrovo = computed(() => attachId.value && platform.value === 'trovo')

const attach = async () => {
  try {
    await Api.post(`streamer/attach/${platform.value}/save`, {
      provider: attachId.value,
    })
    await vm.$store.dispatch('auth/FETCH_STREAMER')
  }
  finally {
    await router.push({ name: 'profile' })
  }
}

onMounted(() => {
  if (attachingTwitch.value || attachingYoutube.value || attachingTrovo.value) {
    attach()
  }
})
</script>

<style lang="scss" scoped>
.profile-platforms {
  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 8px;

    @media (max-width: $--tablet-portrait) {
      grid-template-columns: 1fr;
    }
  }

  &__button {
    /* width: 220px; */
  }

  &__help {
    margin-top: 16px;

    @media (max-width: $--tablet-portrait) {
      text-align: center;
      margin-top: 24px;
    }
  }

  &__error {
    margin-top: 10px;
  }
}
</style>
