import birthYear from 'lib/validators/birthYear'
import email from 'lib/validators/email'
import required from 'lib/validators/required'

export default function validationRules (model) {
  return {
    email: [
      required,
      email,
    ],
    language: [
      required,
    ],
    country: [
      required,
    ],
    gender: [
      required,
    ],
    birthday: [
      required,
      birthYear,
    ],
  }
}
