import {
  CallbackFunction,
  Trigger,
} from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator (rule: any, value: any, callback: CallbackFunction) {
    const maximumYear = new Date().getFullYear() - 12
    const minimumYear = 1960

    if (value < minimumYear) {
      return callback(new Error(`${i18n.tc('validator.birthYearMore')} ${minimumYear - 1}`))
    }
    if (value > maximumYear) {
      return callback(new Error(`${i18n.tc('validator.birthYearLess')} ${maximumYear + 1}`))
    }
    callback()
  },
  trigger: [Trigger.Change, Trigger.Blur],
}
