<template>
  <DashboardSection class="profile-common">
    <template #left>
      <ProfileUserInfo />
    </template>

    <StreamerSettingsForm
      class="profile-common__form"
      is-edit-profile
      :success="success"
      @submit="onSubmit"
    />
  </DashboardSection>
</template>

<script setup>
import StreamerSettingsForm from 'components/forms/StreamerSettingsForm'
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ProfileUserInfo from 'Streamer/pages/StreamerProfile/components/ProfileUserInfo'
import { computed, ref } from 'vue'

import { vm } from '@/main'

const success = ref(false)
const user = computed(() => vm.$store.getters['auth/user'])

const onSubmit = async () => {
  await user.value.save()
  success.value = true

  setTimeout(() => {
    success.value = false
  }, 1000)
}
</script>

<style lang="scss" scoped>
.profile-common {
  &__form {
    ::v-deep {
      .el-form-item__label:before {
        display: none;
      }

      .el-button {
        width: 220px;
        text-transform: none;
        margin-top: 16px;

        @media (max-width: $--tablet-portrait) {
          width: 100%;
        }
      }
    }
  }
}
</style>
