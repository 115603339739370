<template>
  <Advice
    type="danger"
    :title="locale.title"
  >
    <p class="text-m-regular">
      {{ locale.info }}
      <br>
      {{ locale.noSpam }}
      <br><br>
      {{ locale.clickLink }}
      <br><br>
      <span v-if="sended">
        {{ locale.linkClicked }}
      </span>
      <a
        v-else
        href="javascript:;"
        @click="onSendConfirmation"
      >
        {{ locale.link }}
      </a>
    </p>
  </Advice>
</template>

<script setup>
import Advice from 'components/Advice'
import { Api } from 'lib/api/instance'
import { useLocale } from 'lib/helpers/useLocale'
import { computed, ref } from 'vue'

const sended = ref(false)

const locale = computed(() => ({
  title: useLocale('advice.title'),
  info: useLocale('advice.info'),
  noSpam: useLocale('advice.noSpam'),
  clickLink: useLocale('advice.clickLink'),
  link: useLocale('advice.link'),
  linkClicked: useLocale('advice.linkClicked'),
}))

const onSendConfirmation = async () => {
  await Api.get('streamer/profile/email/resend')
  sended.value = true
}
</script>
